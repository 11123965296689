import Flex from '@deprecated-ui/common/Flex';
import device from '@deprecated-ui/_style/device';
import color from '@deprecated-ui/_style/color';
import styled from 'styled-components';
import { Wrapper, SectionTitle } from '../styled';

const SectionWrapper = styled(Wrapper)`
  align-items: center;

  @media ${device.tablet} {
    flex-direction: row;
  }
`;

const BrowserWrapper = styled.div`
  width: 100%;

  @media ${device.tablet} {
    padding-right: 30px;
    width: 50%;
  }

  @media ${device.laptop} {
    padding-right: 50px;
    width: calc(100% - 400px);
  }
`;

const FeatureItem = styled(Flex)`
  align-items: center;
  cursor: pointer;
  display: none;
  flex-direction: column;
  text-align: center;

  span {
    font-family: var(--bold-font);
    font-size: 15px;
    letter-spacing: 0.1em;
    margin-bottom: 10px;
    text-transform: uppercase;
  }

  p {
    font-family: var(--light-font);
    font-size: 18px;
    line-height: 1.3;
    margin-bottom: 20px;
  }

  &.active {
    display: flex;
    span {
      color: ${color.primary};
    }
  }

  @media ${device.tablet} {
    align-items: flex-start;
    text-align: left;
  }

  @media ${device.laptop} {
    display: flex;
    padding-top: 0;
    position: relative;

    &.active {
      display: flex;

      &::after {
        content: '';
        position: absolute;
        left: -30px;
        top: 30px;
        width: 16px;
        height: 16px;
        border: solid 1px #00ab6b;
        border-width: 0 0 1px 1px;
        transform: rotate(45deg);
      }
    }
  }
`;

export const FeaturesButtons = styled(Flex)`
  align-items: center;
  flex-direction: column;
  width: 100%;

  @media ${device.tablet} {
    align-items: flex-start;
  }

  @media ${device.laptop} {
    ${FeatureItem}:nth-of-type(1) {
      animation-delay: 0.2s;
    }

    ${FeatureItem}:nth-of-type(2) {
      animation-delay: 0.35s;
    }

    ${FeatureItem}:nth-of-type(3) {
      animation-delay: 0.5s;
    }

    ${FeatureItem}:nth-of-type(4) {
      animation-delay: 0.65s;
    }
  }
`;

const FeaturesWrapper = styled(Flex)`
  align-items: center;
  flex-direction: column;
  width: 100%;

  @media ${device.tablet} {
    align-items: flex-start;
    padding-left: 30px;
    width: 50%;
  }

  @media ${device.laptop} {
    padding-left: 50px;
    width: 400px;
  }
`;

const Title = styled(SectionTitle)`
  text-align: center;
  margin-bottom: 15px;

  @media ${device.tablet} {
    text-align: left;
  }

  @media ${device.laptop} {
    margin-bottom: 30px;
  }
`;

const BulletList = styled(Flex)`
  display: flex;
  padding-bottom: 20px;

  @media ${device.tablet} {
  }

  @media ${device.laptop} {
    display: none;
  }

  span {
    background-color: ${color.veryLightGrey};
    border-radius: 50%;
    cursor: pointer;
    height: 8px;
    margin: 0 3px;
    width: 8px;

    &.active {
      background-color: ${color.primary};
    }

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`;

export {
  SectionWrapper,
  BulletList,
  BrowserWrapper,
  FeaturesWrapper,
  FeatureItem,
  Title,
};
