/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import { useState } from 'react';
import ReactVisibilitySensor from 'react-visibility-sensor';
import clsx from 'clsx';
import { BrowserBox } from '../uikit';
import { keyFeatures } from '../constants';
import {
  SectionWrapper,
  BulletList,
  BrowserWrapper,
  FeaturesWrapper,
  FeatureItem,
  Title,
  FeaturesButtons,
} from './styled';

function KeyFeatures() {
  const [viewed, setViewed] = useState(false);
  const [tabIndex, setTabIndex] = useState<number>(0);

  return (
    <ReactVisibilitySensor
      offset={{ top: 140, bottom: 140 }}
      partialVisibility
      onChange={(v) => setViewed((s) => s || v)}
    >
      <SectionWrapper>
        <BrowserWrapper>
          <BrowserBox image={keyFeatures[tabIndex].imageUrl} />
        </BrowserWrapper>
        <FeaturesWrapper>
          <Title>Key Features</Title>
          <BulletList>
            {keyFeatures.map((item, index) => (
              <span
                key={index}
                className={tabIndex === index && 'active'}
                onClick={() => setTabIndex(index)}
              />
            ))}
          </BulletList>
          <FeaturesButtons>
            {keyFeatures.map((feature, index) => (
              <FeatureItem
                key={`feature-${index}`}
                onClick={() => setTabIndex(index)}
                className={clsx(
                  'fromRightX',
                  viewed && 'fromRight',
                  tabIndex === index && 'active',
                )}
              >
                <span>{feature.title}</span>
                <p>{feature.description}</p>
              </FeatureItem>
            ))}
          </FeaturesButtons>
        </FeaturesWrapper>
      </SectionWrapper>
    </ReactVisibilitySensor>
  );
}

export default KeyFeatures;
